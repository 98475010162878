import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Carousel, Row, List, Typography, Button, Empty } from "antd";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import SearchBarHome from "./SearchBarHome";

if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dotPosition: "top",
      leadReference: '', // Initialize the state
    };
  }

  componentDidMount() {
    // Add the Google Tag script dynamically
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16741509897";
    document.head.appendChild(script);

    // Initialize gtag function and trigger the conversion event once the script loads
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "AW-16741509897");

      // Trigger the conversion event
      gtag('event', 'conversion', {
        send_to: 'AW-16741509897/QoI8CNTc9d0ZEInW_K4-'
      });
    };
  }

  render() {
    return (
      <div id="wrap" className="color1-inher">
        <div>
          <section className="block-sl">
            <div className="banner-item banner-2x banner-bg-download-center color-inher">
              <img
                src={`${process.env.PUBLIC_URL}/images/chery-white-header-dark.png`}
                alt="logo"
                style={{ width: "18em", marginBottom: "1em" }}
              />
              <h3 className="f-weight-400">
                <strong>Thank you for contacting</strong>
                <br />
                Chery Klerksdorp
                <br />
                <br />
                <span style={{ color: "black ", fontSize: "17px" }}>
                  Our vehicle specialist will be in touch shortly
                </span>
                <br />
              </h3>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(SharedLayout);
