import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions";
import Home from "./Home";
import SharedLayout from "./SharedLayout";
import Stockshop from "./StockSpace/StockShop";
import StockNew from "./StockSpace/StockNew";
import StockPromotions from "./StockSpace/StockPromotions";
import ProductsShop from "./ProductsSpace/ProductsShop";
import About from "./about-kia";
import BookService from "./bookService";
import ContactUs from "./contactUs";
import ThankYou from "./thank-you";
import Tiggo4Pro from "./StockSpace/Tiggo4Pro";
import Tiggo7Pro from "./StockSpace/Tiggo7Pro";
import Tiggo7ProMax from "./StockSpace/Tiggo7ProMax";
import Tiggo8Pro from "./StockSpace/Tiggo8Pro";
import Tiggo8ProMax from "./StockSpace/Tiggo8ProMax";
import Tiggo8ProMaxMy23 from "./StockSpace/Tiggo8ProMaxMy23";
import TiggoCross from "./StockSpace/TiggoCross";
import CartFull from "./CartFull";
import StockDetails from "./StockSpace/StockDetails";
import DownloadCenter from "./downloadCenter";
import WishList from "./WishList";
import Blogs from "./Blogs/blogs";
import AllPosts from "./Blogs/all-posts";
import OnePost from "./Blogs/one-post";

import LegalNotice from "./Legal/legal-notice";
import PopiaManual from "./Legal/POPIA-manual";
import PrivacyPolicy from "./Legal/privacy-policy";
import Terms from "./Legal/terms";
import Vacancies from "./Vacancies";

class App extends Component {
  constructor() {
    super();
    this.state = {
      stockfilters: {},
    };
  }

  setStockFilters(stockfilters) {
    this.setState({ stockfilters });
  }

  render() {
    return (
      <BrowserRouter>
        <SharedLayout stockfilters={this.setStockFilters.bind(this)}>
          <Route exact path="/" component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/products" component={ProductsShop} />
          <Route
            path="/stock"
            render={() => <Stockshop filters={this.state.stockfilters} />}
          />
          <Route
            path="/promotions"
            render={() => <StockPromotions filters={this.state.stockfilters} />}
          />
          <Route
            path="/new-vehicles"
            render={() => <StockNew filters={this.state.stockfilters} />}
          />
          <Route path="/thank-you" component={ThankYou} />
          <Route path="/stocko" component={StockDetails} />
          <Route path="/tiggo-4-pro" component={Tiggo4Pro} />
          <Route path="/tiggo-7-pro" component={Tiggo7Pro} />
          <Route path="/tiggo-7-pro-max" component={Tiggo7ProMax} />
          <Route path="/tiggo-8-pro" component={Tiggo8Pro} />
          <Route path="/tiggo-8-pro-max" component={Tiggo8ProMax} />
          <Route path="/tiggo-8-pro-max-my-23" component={Tiggo8ProMaxMy23} />
          <Route path="/tiggo-cross" component={TiggoCross} />
          <Route path="/about-kia" component={About} />
          <Route path="/bookService" component={BookService} />
          <Route path="/Vacancies" component={Vacancies} />
          <Route path="/contactus" component={ContactUs} />

          <Route path="/downloadCenter" component={DownloadCenter} />
          <Route path="/products_cart" component={CartFull} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/wishlist" component={WishList} />
          <Route path="/stock/vehicle-details" component={Stockshop} />
          <Route path="/products/vehicle-details" component={ProductsShop} />
          <Route path="/legal-notice" component={LegalNotice} />
          <Route path="/POPIA-manual" component={PopiaManual} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms" component={Terms} />
          <Route component={OnePost} path="/:slug"></Route>
          <Route component={AllPosts} path="/all-posts"></Route>
        </SharedLayout>
      </BrowserRouter>
    );
  }
}

export default connect(null, actions)(App);
