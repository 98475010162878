import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Tabs,
  Badge,
  Image,
  Radio,
  Space,
  Checkbox,
  Form,
  Select,
  Input,
  Modal,
  Collapse,
  Button,
  Carousel,
} from "antd";
import CurrencyFormat from "react-currency-format";
import styled from "styled-components";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
const { Option, OptGroup } = Select;
const { TextArea } = Input;
const text = `
* All calculations, rates and payments shown are GUIDELINES ONLY and are not quotations. Final rates and payments can only be determined by your Finance House once they have assessed all your information (completed in a finance application form). By using this calculator, you hereby INDEMNIFY Mega Theron Group, its holding, associated or partner companies against any loss or liability which you may suffer as a result of using the calculator. All information provided to you on this website is for illustrative purposes only and may not be considered to be ADVICE.
`;
const { Panel } = Collapse;
const StyledCheckbox = styled(Checkbox)`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: black !important;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: red !important;
    border: 0px solid black !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: red!important;
}
.ant-checkbox-checked::after {
  background-color: transparent !important;
  border-color: red !important;
  border: 0px solid red !important;
}
  }
`;
const StyledInput = styled(Input)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: transparent !important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid black !important;
  border-radius: 0px;
  color: white!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledModalTestDrive = styled(Modal)`
  .ant-modal-header {
    display: none;
    border-radius: 0.5em !important;
    text-align: center !important;
    border-bottom: 0px solid #f0f0f0 !important;
  }
  .ant-modal-content {
    // padding-left: 1.5em;
    // padding-right: 1.5em;
    padding-top: 0.1em;
    // height: 36em !important;
    max-width: 40em;
    margin: auto !important;
    background-color: white !important;
  }
  @media (max-width: 1100px) {
    .ant-modal-content {
      padding-left: 1.5em;
      padding-right: 1.5em;
      padding-top: 0.1em;
      padding-bottom: 1.5em;
      height: 100% !important;
      // width: 100vw;
      margin: auto !important;
      background-color: white !important;
    }
  }
`;
if (window.innerWidth <= 770 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}
const settings = {
  dots: false,
  infinite: true,
  speed: 400,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
class FinanceBox extends React.Component {
  state = {
    amountValue: 30000,
    monthsValue: 12,
    depositValue: 0,
    balloonValue: 0,
    interestValue: 7,
  };
  handleAmountChange = (value) => {
    this.setState({ amountValue: value });
  };
  handleDepositChange = (value) => {
    this.setState({ depositValue: value });
  };
  handleMonthsChange = (value) => {
    this.setState({ monthsValue: value });
  };
  handleBalloonChange = (value) => {
    this.setState({ balloonValue: value });
  };
  handleInterestChange = (value) => {
    this.setState({ interestValue: value });
  };

  render() {
    let {
      amountValue,
      depositValue,
      monthsValue,
      balloonValue,
      interestValue,
    } = this.state;

    return (
      <Row style={{ marginBottom: "2em" }}>
        <Col span={14}>
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}>Vehicle Amount</span>
            <span className="color-red" style={{ float: "right" }}>
              <CurrencyFormat
                value={amountValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R "}
                //format='R ### ### ### ###'
                renderText={(amountValue) => <div>{amountValue}</div>}
              />
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={600000}
            minValue={30000}
            value={amountValue}
            onChange={this.handleAmountChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Deposit Amount</span>{" "}
            <span className="color-red" style={{ float: "right" }}>
              <CurrencyFormat
                value={depositValue}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"R "}
                //format='R ### ### ### ###'
                renderText={(depositValue) => <div>{depositValue}</div>}
              />
            </span>
          </h5>
          <InputRange
            step={100}
            maxValue={amountValue - 30000}
            minValue={0}
            value={depositValue}
            onChange={this.handleDepositChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Over</span>
            <span className="color-red" style={{ float: "right" }}>
              {monthsValue} Month{monthsValue > 1 && "s"}
            </span>
          </h5>
          <InputRange
            step={12}
            maxValue={96}
            minValue={12}
            value={monthsValue}
            onChange={this.handleMonthsChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Interest Rate</span>
            <span className="color-red" style={{ float: "right" }}>
              {interestValue}%
            </span>
          </h5>
          <InputRange
            step={0.25}
            maxValue={17}
            minValue={7}
            value={interestValue}
            onChange={this.handleInterestChange}
          />
          <h5
            className="m-b-lg-25"
            style={{ width: "100%", textTransform: "none" }}
          >
            <span style={{ float: "left" }}> Balloon Payment</span>
            <span className="color-red" style={{ float: "right" }}>
              {balloonValue}%
            </span>
          </h5>
          <InputRange
            step={1}
            maxValue={8}
            minValue={0}
            value={balloonValue}
            onChange={this.handleBalloonChange}
          />
        </Col>{" "}
        <Col span={10}>
          <Display
            months={monthsValue}
            amount={amountValue}
            deposit={depositValue}
            balloon={balloonValue}
            interest={interestValue}
          />
        </Col>
      </Row>
    );
  }
}
class Display extends React.Component {
  state = {
    APR: 0.05,
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.calculateAPR();
    }
  }

  calculateAPR = () => {
    let { amount } = this.props;

    if (1000 < amount && amount < 5000) {
      this.setState({ APR: 0.05 });
    }
    if (5000 < amount && amount < 10000) {
      this.setState({ APR: 0.1 });
    }
    if (10000 < amount && amount < 15000) {
      this.setState({ APR: 0.15 });
    }
    if (15000 < amount && amount < 20000) {
      this.setState({ APR: 0.2 });
    }
  };

  calculateMonthlyRepayment = () => {
    let { amount, months, deposit, interest, balloon } = this.props;
    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentLast = Math.round(
      monthlyRepayment < 0 ? "0" : monthlyRepayment
    );

    //let { amount, months, deposit, interest } = this.props;
    //let amountInterestDec = interest / 100;
    //let amountInterestMonth = amountInterestDec / 12;
    //let amountInterest = amountInterestMonth;
    //let montsCalc = Math.pow(1 + amountInterest, months);
    //let monthlyRepayment1 = amountInterest * montsCalc;
    //let monthlyRepayment2 = montsCalc - 1;
    //let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    //let amountDeDeposit = amount - deposit;
    //let monthlyRepaymentTotal = amountDeDeposit * monthlyRepaymentIntersest;
    //let monthlyRepayment = monthlyRepaymentTotal + 94;

    return (
      <h4 className="finance-box-p color-red" style={{ marginTop: "-1em" }}>
        <CurrencyFormat
          value={monthlyRepaymentLast}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"R "}
          //format='R ### ### ### ###'
          renderText={(monthlyRepaymentLast) => (
            <div>{monthlyRepaymentLast}</div>
          )}
        />
      </h4>
    );
  };
  calculateInterest = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentInterest =
      monthlyRepayment * months - (amount - deposit);
    let monthlyRepaymentLast = Math.round(
      monthlyRepaymentInterest < 0 ? "0" : monthlyRepaymentInterest
    );
    return (
      <span>
        <h4 className="finance-box-p color-red">
          <CurrencyFormat
            value={monthlyRepaymentLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={(monthlyRepaymentLast) => (
              <div>{monthlyRepaymentLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Interest Amount</p>
      </span>
    );
  };
  calculateTotal = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal * months;
    let monthlyRepaymentall = monthlyRepayment;
    let monthlyRepaymentallLast = Math.round(
      monthlyRepaymentall < 0 ? "0" : monthlyRepaymentall
    );
    return (
      <span>
        <h4 className="finance-box-p color-red">
          <CurrencyFormat
            value={monthlyRepaymentallLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={(monthlyRepaymentallLast) => (
              <div>{monthlyRepaymentallLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Total Repayment</p>
      </span>
    );
  };
  calculateAmount = () => {
    let { amount, months, deposit, interest, balloon } = this.props;

    let amountBalloon = amount * (balloon / 100);
    let amountInterestDec = interest / 100;
    let amountInterestMonth = amountInterestDec / 12;
    let amountInterest = amountInterestMonth;
    let montsCalc = Math.pow(1 + amountInterest, months);
    let monthlyRepayment1 = amountInterest * montsCalc;
    let monthlyRepayment2 = montsCalc - 1;
    let monthlyRepaymentIntersest = monthlyRepayment1 / monthlyRepayment2;
    let amountDeDeposit = amount - deposit;
    let monthlyRepaymentTotal =
      (amountDeDeposit - amountBalloon) * monthlyRepaymentIntersest;
    let monthlyRepayment = monthlyRepaymentTotal;
    let monthlyRepaymentLast = Math.round(
      amount - deposit < 0 ? "0" : amount - deposit
    );
    return (
      <span>
        <h4 className="finance-box-p color-red">
          <CurrencyFormat
            value={monthlyRepaymentLast}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"R "}
            //format='R ### ### ### ###'
            renderText={(monthlyRepaymentLast) => (
              <div>{monthlyRepaymentLast}</div>
            )}
          />
        </h4>

        <p style={{ marginBottom: "0em" }}>Amount To Finance</p>
      </span>
    );
  };
  percentageAPR = () => {
    return <h4 className="finance-box-p color-red">{this.state.APR * 100}%</h4>;
  };

  render() {
    return (
      <div className="finance-box-flex">
        {/*<DisplayChild func={this.percentageAPR()} text='Interest Rate' />*/}
        <DisplayChild
          func={this.calculateMonthlyRepayment()}
          intr={this.calculateInterest()}
          total={this.calculateTotal()}
          fin={this.calculateAmount()}
          text="Monthly Repayment"
        />
      </div>
    );
  }
}

const DisplayChild = ({ func, text, intr, total, fin }) => {
  return (
    <span>
      {func} <p style={{ marginBottom: "0em" }}>{text}</p>
      <br />
      {fin}
      <br />
      {intr}
      <br />
      {total}
    </span>
  );
};
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}
const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};
class StockDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      valueRadio: "Speak to a Dealer", isDisabled: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }
  componentDidMount() {
    console.log(this.props.selectedStock);
    document.documentElement.scrollTop = 0;
  }
  onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      valueRadio: e.target.value,
    });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    window.location.assign("./");
  };
  handleSubmit = (values) => {
    this.onFinish(values);
    this.setState({ isDisabled: true });
  };
  onFinish = (values) => {
    var { email, stockNumber, make, series, modelDesc, modelYear, valueRadio } =
      this.props.selectedStock;
    //var { valueRadio } = this.state.valueRadio;
    const MSG = {
      to: ["leads@megatheron.co.za"],
      from: "info@mtgroup.co.za",
      //to: [  //"leads@megatheron.co.za", "monique@moniqs-interiors.com"],
      subject: "New Vehicle Enquiry",
      text: " ",
      html: `
    
      <style type="text/css">  
      body, #bodyTable{background-color:white}
      #emailHeader{background-color:white}
      #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
      #emailFooter{background-color:#E1E1E1;}      
</style>
<body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
<center style="background-color:#E1E1E1;">
<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
  <tr>
    <td align="center" valign="top" id="bodyCell">      
      <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
        <!-- HEADER ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                        <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                          <tr>
                       
                            <td align="right" valign="middle" class="flexibleContainerBox">
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                <tr>
                                  <td align="left" class="textContent">
                                    <!-- CONTENT // -->
                                   
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // END -->
      </table>     
      <!-- EMAIL BODY // -->   
      <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
        <!-- MODULE ROW // -->
        <tr>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#101010">
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                 
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                        <!-- CONTENT TABLE // -->
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                               <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                <img alt="" src="https://i.ibb.co/z5SfxC9/mtgroup.png" width="300" height="85" style="display: block; border-radius: 8px 8px 8px 8px; font-family: Lato, Helvetica, Arial, sans-serif; color: #9AA31E; font-size: 18px;margin-top: 18px;" border="0">     <tr>
                            <td align="center" valign="top" class="textContent">
                              <h1 style="color:white;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Vehicle Enquiry</h1>
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: white;line-height:135%;">You have received a new Vehicle Enquiry from <br /> Chery website</h2>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->
                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- MODULE ROW // -->
        <tr mc:hideable>
          <td align="center" valign="top">
            <!-- CENTERING TABLE // -->
            <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
              <tr>
                <td align="center" valign="top">
                  <!-- FLEXIBLE CONTAINER // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                    <tr>
                      <td valign="top" width="500" class="flexibleContainerCell">
                        <!-- CONTENT TABLE // -->
                        <table p align="left" border="0" cellpadding="0" cellspacing="0" width="120%">
                          <tr>
                            <td align="center" valign="top" class="flexibleContainerBox">
                               <table border="0" cellspacing="0" cellpadding="0" >
            
                        <tr style="font-family: sans-serif; font-size: 17px; letter-spacing: 1px; color:black">
                          <td class="role"><br />
                          <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                          <strong> Number </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.number}<br />
                          <strong> Email </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.email}<br />
                  
                          <strong> Make </strong> &nbsp;&nbsp;:&nbsp;&nbsp; TIGGO<br />
                          <strong> Model </strong> &nbsp;&nbsp;:&nbsp;&nbsp; 8 PRO<br />
                  
                          <strong> Request </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${this.state.valueRadio}<br />
                 
                      
                       
                             <br /><br />
                          </td>
                        </tr>
                        <table>
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
            <!-- // CENTERING TABLE -->
          </td>
        </tr>
        <!-- // MODULE ROW -->


        <!-- MODULE ROW // -->
       
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#101010" style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:-80px;color: lightgray;line-height:135%;">
              <tr>
                <td align="center" valign="top">
                
                  <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                    <tr>
                      <td align="center" valign="top" width="500" class="flexibleContainerCell">

                    
                        <table border="0" cellpadding="30" cellspacing="0" width="100%">
                      
                    
                          <tr>
                            <td align="center" valign="top" class="textContent">
        
                              <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: grey;line-height:135%;">2024@CHERY</h2>
               
                            </td>
                          </tr>
                        </table>
                        <!-- // CONTENT TABLE -->

                      </td>
                    </tr>
                  </table>
                  <!-- // FLEXIBLE CONTAINER -->
                </td>
              </tr>
            </table>
     `,
    };
    fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(MSG),
    }).then(async (res) => {
      if (!res.ok) {
        console.log("handle error");
      }
      console.log("handle success");
      this.setState({
        visible: true,
        submitDisabled: true,
        name: "",
        number: "",
        email: "",
      });
      this.props.history.push("/thank-you");
    });
  };
  scrollOnclick() {
    // window.location.reload();
    document.documentElement.scrollTop = 0;
  }
  render() {
    const { dotPosition } = this.state;
    console.log(this.props.selectedStock);
    var {
      make,
      series,
      modelDesc,
      modelYear,
      email,
      number,
      regDate,
      extColour,
      intColour,
      retailPriceIncl,
      fuelType,
      capacityCC,
      bodyType,
      transmission,
      engineSize,
      vehicleDrive,
      unladenWeight,
      axles,
      doors,
      cylinders,
      passengers,
      immobiliser,
      dateBuilt,
      extrasAndAccessories,
      comments,
      price,
      branch,
      brand,
      email,
      onSale,
      condition,
      mileage,
      fuelTankSize,
      degreeCamera,
      fourWheelDrive,
      abs,
      driverAssistance,
      airBags,
      aircon,
      alarm,
      mobileCarPlay,
      automaticLights,
      automaticWipers,
      autonomousBraking,
      blindSpotMonitoring,
      bluetooth,
      brakeAssist,
      climateControl,
      cruiseControl,
      daytimeLights,
      electricParkingBrake,
      elctricWindows,
      electronicStability,
      collisionWarning,
      headDisplay,
      heatedSeats,
      hillAssist,
      hillControl,
      keylessEntry,
      laneWarning,
      laneAssist,
      leatherSeats,
      ledLights,
      navigation,
      paddleShift,
      parkControl,
      powerBoot,
      powerSeat,
      remoteStart,
      reverseCamera,
      sunroof,
      touchScreen,
      towbar,
      signRecognition,
      tyreMonitor,
      usbPort,
      voiceRecognition,
      warranty,
      wirelessCharging,
      xenonLights,
      frontLeftImage,
      frontImage,
      frontRightImage,
      rightSideImage,
      wheelsImage,
      backRightImage,
      backImage,
      backLeftImage,
      leftSideImage,
      bootImage,
      frontSeatImage,
      backSeatImage,
      dashPassengerImage,
      dashDriverImage,
      dashDriverSideImage,
      radioImage,
      radioCloseImage,
      gearsImage,
      steeringImage,
      spareKeyImage,
      branchImage,
    } = this.props.selectedStock;
    const images = [
      {
        original: frontLeftImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontLeftImage}`
          : null,
        thumbnail: frontLeftImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontLeftImage}`
          : null,
      },
      {
        original: frontImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontImage}`
          : null,
        thumbnail: frontImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontImage}`
          : null,
      },
      {
        original: frontRightImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontRightImage}`
          : null,
        thumbnail: frontRightImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${frontRightImage}`
          : null,
      },
      {
        original: rightSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${rightSideImage}`
          : null,
        thumbnail: rightSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${rightSideImage}`
          : null,
      },
      {
        original: backRightImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backRightImage}`
          : null,
        thumbnail: backRightImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backRightImage}`
          : null,
      },
      {
        original: backImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backImage}`
          : null,
        thumbnail: backImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backImage}`
          : null,
      },
      {
        original: backLeftImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backLeftImage}`
          : null,
        thumbnail: backLeftImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backLeftImage}`
          : null,
      },
      {
        original: leftSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${leftSideImage}`
          : null,
        thumbnail: leftSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${leftSideImage}`
          : null,
      },
      {
        original: bootImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${bootImage}`
          : null,
        thumbnail: bootImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${bootImage}`
          : null,
      },
      {
        original: backSeatImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backSeatImage}`
          : null,
        thumbnail: backSeatImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${backSeatImage}`
          : null,
      },
      {
        original: dashPassengerImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashPassengerImage}`
          : null,
        thumbnail: dashPassengerImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashPassengerImage}`
          : null,
      },
      {
        original: dashDriverImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverImage}`
          : null,
        thumbnail: dashDriverImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverImage}`
          : null,
      },
      {
        original: dashDriverSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverSideImage}`
          : null,
        thumbnail: dashDriverSideImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${dashDriverSideImage}`
          : null,
      },
      {
        original: radioImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioImage}`
          : null,
        thumbnail: radioImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioImage}`
          : null,
      },
      {
        original: radioCloseImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioCloseImage}`
          : null,
        thumbnail: radioCloseImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${radioCloseImage}`
          : null,
      },
      {
        original: gearsImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${gearsImage}`
          : null,
        thumbnail: gearsImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${gearsImage}`
          : null,
      },
      {
        original: steeringImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${steeringImage}`
          : null,
        thumbnail: steeringImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${steeringImage}`
          : null,
      },
      {
        original: spareKeyImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${spareKeyImage}`
          : null,
        thumbnail: spareKeyImage
          ? `${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${spareKeyImage}`
          : null,
      },
    ];
    return (
      <div className="wrap-body-inner" style={{ margin: "auto" }}>
        <Row className="desktop-view">
          <Col xs={24} md={13}>
            <div>
              <Carousel
                autoplay
                dotPosition={dotPosition}
                dots={true}
                effect="fade"
              >
                <span>
                  <video
                    style={{
                      width: "100%",
                      height: "100vh",
                      objectFit: "cover",
                      overflowClipBox: "content-box",
                      overflow: "clip",
                      objectPosition: "center",
                    }}
                    autoPlay
                    muted
                    loop
                  >
                    <source
                      src={`${process.env.PUBLIC_URL}/images/chery-new/Tiggo8pro-new-video.webm`}
                      type="video/mp4"
                    ></source>
                  </video>
                </span>
              </Carousel>
            </div>
          </Col>
          <Col xs={24} md={10}>
            <div
              className="m-t-lg-30"
              style={{
                textAlign: "center",
                padding: "0.5em",
                minWidth: "100%",
                margin: "auto",
                position: "absolute",
                top: "50%",
                left: "55%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Fade bottom>
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "0.5em",
                      maxWidth: "45em",
                      margin: "auto",
                    }}
                  >
                    <Row style={{ margin: "auto" }}>
                      <Col span={24} style={{ marginBottom: "0.5em" }}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8p.png`}
                          alt=""
                          style={{
                            width: "18em",
                            marginTop: "0.5em",
                            marginBottom: "1em",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "25px",
                            textTransform: "none",
                            fontFamily: "TacticSans",
                            display: "block",
                            color: "grey",
                          }}
                        >
                          Enjoy Your First Class
                        </span>
                      </Col>
                      <Col span={8}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <Link
                            // onClick={() => this.props.fetchSingleStock(stock)}
                            to={`/tiggo-8-pro`}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSansBd",
                                display: "block",
                                color: "#c50d27",
                              }}
                            >
                              360° Panoramic <br />
                            </span>
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSans",
                                color: "grey",
                              }}
                            >
                              Imaging Camera
                              <br />
                            </span>
                          </Link>
                        </h4>
                      </Col>
                      <Col span={8}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <Link
                            // onClick={() => this.props.fetchSingleStock(stock)}
                            to={`/tiggo-8-pro`}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSansBd",
                                display: "block",
                                color: "#c50d27",
                              }}
                            >
                              12.3" Digital
                              <br />
                            </span>
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSans",
                                color: "grey",
                              }}
                            >
                              Instrumentation
                              <br />
                            </span>
                          </Link>
                        </h4>
                      </Col>
                      <Col span={8}>
                        <h4
                          className="product-name"
                          style={{ marginBottom: "0em" }}
                        >
                          <Link
                            // onClick={() => this.props.fetchSingleStock(stock)}
                            to={`/tiggo-8-pro`}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSansBd",
                                display: "block",
                                color: "#c50d27",
                              }}
                            >
                              Adaptive
                              <br />
                            </span>
                            <span
                              style={{
                                fontSize: "12px",
                                textTransform: "none",
                                fontFamily: "TacticSans",
                                color: "grey",
                              }}
                            >
                              Cruise Control
                              <br />
                            </span>
                          </Link>
                        </h4>
                      </Col>
                    </Row>
                  </div>
                  <div className="bg1-gray-2">
                    <Form
                      style={{ maxWidth: "45em", margin: "auto" }}
                      // id='book-service'
                      onFinish={this.handleSubmit}
                      initialValues={{
                        remember: true,
                      }}
                      name="wrap"
                      labelCol={{ flex: "110px" }}
                      labelAlign="left"
                      labelWrap
                      wrapperCol={{ flex: 1 }}
                      colon={false}
                    >
                      <div
                        className="m-b-lg-20"
                        style={{ maxWidth: "45em", margin: "auto" }}
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Item
                              name="name"
                              rules={[{ required: true, message: false }]}
                              hasFeedback
                            >
                              <StyledInput placeholder="Name" />
                            </Form.Item>
                          </div>

                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Item
                              name="number"
                              rules={[
                                {
                                  //max:10,
                                  //min:10,
                                  required: true,
                                  message: false,
                                  //type: "number",
                                },
                              ]}
                              hasFeedback
                            >
                              <StyledInput placeholder="Number" />
                            </Form.Item>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <Form.Item
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: false,
                                  type: "email",
                                },
                              ]}
                              hasFeedback
                            >
                              <StyledInput placeholder="Email" />
                            </Form.Item>
                          </div>

                          <div className="text-center col-sm-12 col-md-12 col-lg-12">
                            <Form.Item
                              name="book"
                              rules={[{ required: false, message: false }]}
                            >
                              <Radio.Group
                                onChange={this.onChangeRadio}
                                value={this.state.valueRadio}
                              >
                                <Space
                                  direction="horizontal"
                                  style={{
                                    display: window.isPhone ? "grid" : "",
                                  }}
                                >
                                  <Radio
                                    style={{ color: "black" }}
                                    value="Speak to a Dealer"
                                  >
                                    Speak to a Dealer
                                  </Radio>
                                  <Radio
                                    style={{ color: "black" }}
                                    value="Book a Test Drive"
                                  >
                                    Book a Test Drive
                                  </Radio>
                                </Space>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                          <div className="col-lg-12 m-t-lg-12 text-center">
                            <Form.Item>
                              <Button
                                style={{
                                  fontFamily: "Arial",
                                  border: "1px solid #CD2934",
                                  fontSize: "15px",
                                  fontWeight: 600,
                                  borderRadius: "0em",
                                  backgroundColor: "#CD2934",
                                  color: "white",
                                  height: "55px",
                                  width: "100%",
                                  paddingLeft: "3em",
                                  paddingRight: "3em",
                                  textShadow: "none",
                                  margin: "auto",
                                  marginTop: "0em",
                                }}
                                type="primary"
                                htmlType="submit"
                                disabled={this.state.isDisabled}
                              >
                                Send Request
                              </Button>{" "}
                            </Form.Item>{" "}
                            <p
                              style={{
                                color: "black",
                                //marginBottom: "2.5em",
                                marginTop: "0em",
                                fontSize: "13px",
                                lineHeight: "1.5em",
                              }}
                            >
                              By submitting this form, you agree to our{" "}
                              <span
                                style={{
                                  fontSize: "13px",
                                  textDecoration: "underline",
                                }}
                              >
                                <Link style={{ color: "black" }} to="/terms">
                                  Terms of Use
                                </Link>
                              </span>{" "}
                              and{" "}
                              <span
                                style={{
                                  fontSize: "13px",
                                  textDecoration: "underline",
                                }}
                              >
                                <Link
                                  style={{ color: "black" }}
                                  to="/privacy-policy"
                                >
                                  Privacy Statement
                                </Link>
                              </span>
                              .
                            </p>{" "}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>{" "}
                </div>
              </Fade>
            </div>
          </Col>
        </Row>
        <Row className="mobile-view">
          <Col xs={24} md={13}>
            <div>
              <Carousel
                autoplay
                dotPosition={dotPosition}
                dots={true}
                effect="fade"
              >
                <span>
                  <video
                    style={{
                      width: "100%",
                      height: "30em",
                      objectFit: "cover",
                      overflowClipBox: "content-box",
                      overflow: "clip",
                      objectPosition: "center",
                    }}
                    autoPlay
                    muted
                    loop
                  >
                    <source
                      src={`${process.env.PUBLIC_URL}/images/chery-new/Tiggo8pro-new-video.webm`}
                      type="video/mp4"
                    ></source>
                  </video>
                </span>
              </Carousel>
            </div>
          </Col>
          <Col xs={24} md={10}>
            <div
              className="m-t-lg-30"
              style={{
                textAlign: "center",
                padding: "0.5em",
                minWidth: "100%",
                margin: "auto",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: "0.5em",
                  maxWidth: "45em",
                  margin: "auto",
                }}
              >
                <Row style={{ margin: "auto" }}>
                  <Col span={24} style={{ marginBottom: "0.5em" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8p.png`}
                      alt=""
                      style={{
                        width: "18em",
                        marginTop: "0.5em",
                        marginBottom: "1em",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "25px",
                        textTransform: "none",
                        fontFamily: "TacticSans",
                        display: "block",
                        color: "grey",
                      }}
                    >
                      Enjoy Your First Class
                    </span>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      className="f-16 m-t-lg-5  mn-cl"
                      style={{ color: "black" }}
                    >
                      FROM
                    </div>
                    <div
                      className="f-40 m-t-lg-0 mn-cl"
                      style={{ color: "black" }}
                    >
                      <CurrencyFormat
                        value="504 900"
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <h4
                      className="product-name"
                      style={{ marginBottom: "0em", paddingBottom: 0 }}
                    >
                      <Link
                        // onClick={() => this.props.fetchSingleStock(stock)}
                        to={`/tiggo-8-pro`}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSansBd",
                            display: "block",
                            color: "#c50d27",
                          }}
                        >
                          360° Panoramic
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSans",
                            color: "grey",
                          }}
                        >
                          Imaging Camera
                          <br />
                        </span>
                      </Link>
                    </h4>
                  </Col>
                  <Col span={24}>
                    <h4
                      className="product-name"
                      style={{ marginBottom: "0em", paddingBottom: 0 }}
                    >
                      <Link
                        // onClick={() => this.props.fetchSingleStock(stock)}
                        to={`/tiggo-8-pro`}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSansBd",
                            display: "block",
                            color: "#c50d27",
                          }}
                        >
                          12.3" Digital
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSans",
                            color: "grey",
                          }}
                        >
                          Instrumentation
                          <br />
                        </span>
                      </Link>
                    </h4>
                  </Col>
                  <Col span={24}>
                    <h4
                      className="product-name"
                      style={{ marginBottom: "0em" }}
                    >
                      <Link
                        // onClick={() => this.props.fetchSingleStock(stock)}
                        to={`/tiggo-8-pro`}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSansBd",
                            display: "block",
                            color: "#c50d27",
                          }}
                        >
                          Adaptive
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            textTransform: "none",
                            fontFamily: "TacticSans",
                            color: "grey",
                          }}
                        >
                          Cruise Control
                          <br />
                        </span>
                      </Link>
                    </h4>
                  </Col>
                </Row>
              </div>
              <div className="bg1-gray-2">
                <Form
                  style={{ maxWidth: "45em", margin: "auto" }}
                  // id='book-service'
                  onFinish={this.handleSubmit}
                  initialValues={{
                    remember: true,
                  }}
                  name="wrap"
                  labelCol={{ flex: "110px" }}
                  labelAlign="left"
                  labelWrap
                  wrapperCol={{ flex: 1 }}
                  colon={false}
                >
                  <div
                    className="m-b-lg-20"
                    style={{ maxWidth: "45em", margin: "auto" }}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="name"
                          rules={[{ required: true, message: false }]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Name" />
                        </Form.Item>
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="number"
                          rules={[
                            {
                              //max:10,
                              //min:10,
                              required: true,
                              message: false,
                              //type: "number",
                            },
                          ]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Number" />
                        </Form.Item>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="email"
                          rules={[
                            { required: true, message: false, type: "email" },
                          ]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Email" />
                        </Form.Item>
                      </div>

                      <div className="text-center col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="book"
                          rules={[{ required: false, message: false }]}
                        >
                          <Radio.Group
                            onChange={this.onChangeRadio}
                            value={this.state.valueRadio}
                          >
                            <Space
                              direction="horizontal"
                              style={{
                                display: window.isPhone ? "grid" : "",
                              }}
                            >
                              <Radio
                                style={{ color: "black" }}
                                value="Speak to a Dealer"
                              >
                                Speak to a Dealer
                              </Radio>
                              <Radio
                                style={{ color: "black" }}
                                value="Book a Test Drive"
                              >
                                Book a Test Drive
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="col-lg-12 m-t-lg-12 text-center">
                        <Form.Item>
                          <Button
                            style={{
                              fontFamily: "Arial",
                              border: "1px solid #CD2934",
                              fontSize: "15px",
                              fontWeight: 600,
                              borderRadius: "0em",
                              backgroundColor: "#CD2934",
                              color: "white",
                              height: "55px",
                              width: "100%",
                              paddingLeft: "3em",
                              paddingRight: "3em",
                              textShadow: "none",
                              margin: "auto",
                              marginTop: "0em",
                            }}
                            type="primary"
                            htmlType="submit"
                            disabled={this.state.isDisabled}
                          >
                            Send Request
                          </Button>{" "}
                        </Form.Item>{" "}
                        <p
                          style={{
                            color: "black",
                            //marginBottom: "2.5em",
                            marginTop: "0em",
                            fontSize: "13px",
                            lineHeight: "1.5em",
                          }}
                        >
                          By submitting this form, you agree to our{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link style={{ color: "black" }} to="/terms">
                              Terms of Use
                            </Link>
                          </span>{" "}
                          and{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link
                              style={{ color: "black" }}
                              to="/privacy-policy"
                            >
                              Privacy Statement
                            </Link>
                          </span>
                          .
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>{" "}
            </div>
          </Col>
        </Row>
        <section
          className=" m-t-xs-0"
          style={{ margin: "auto", marginTop: "0em", maxWidth: "100em" }}
        >
          <div
            className="col-sm-12 col-md-12 desktop-view"
            style={{ height: "9em", marginTop: "1em" }}
          >
            <Row
              gutter={16}
              style={{
                borderRadius: "1.5em",
              }}
            >
              <Col sm={24} md={18}>
                <Card
                  bordered={false}
                  style={{ padding: 0, backgroundColor: "transparent" }}
                >
                  <h4
                    style={{
                      color: "black",
                      paddingTop: "0em",
                      paddingBottom: "0em",
                      paddingRight: "0.2em",
                      marginTop: "-1em",
                    }}
                    className="product-name "
                  >
                    <span className="f-40" style={{ fontFamily: "TacticSans" }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8p.png`}
                        alt=""
                        style={{ width: "8em" }}
                      />
                      <p
                        className="f-20"
                        style={{ color: "black", fontFamily: "TacticSans" }}
                      >
                        ENJOY YOUR FIRST CLASS
                      </p>
                    </span>
                  </h4>{" "}
                </Card>
              </Col>
              <Col sm={24} md={6}>
                <Card
                  bordered={false}
                  style={{ padding: 0, backgroundColor: "transparent" }}
                >
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <div
                      className="f-12 m-t-lg-0  mn-cl"
                      style={{ color: "black", fontFamily: "TacticSans" }}
                    >
                      FROM
                    </div>
                    <div
                      className="f-40 m-t-lg-0 mn-cl"
                      style={{ color: "black", fontFamily: "TacticSans" }}
                    >
                      <CurrencyFormat
                        value="504 900"
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>{" "}
          </div>
          {/* <div className='col-sm-12 col-md-12 mobile-view' style={{ height: "9em", marginTop: "1em", marginBottom: "8em" }}>

            <Row

              style={{
                //padding: "2em",
                // boxShadow: "2px 2px 10px 2px #ecececd7",
                borderRadius: "1.5em",
              }}
            >
              <Col sm={24} md={18} style={{ width: "100vw" }}>
                <Card bordered={false} style={{ padding: 0, backgroundColor: "transparent", textAlign: "center" }}>
                  <h4
                    style={{
                      color: "black",
                      paddingTop: "0em",
                      paddingBottom: "0em",
                      paddingRight: "0.2em",
                      marginTop: "-1em",

                    }}
                    className='product-name '

                  >
                    <span className='f-40' style={{ fontFamily: "TacticSans", }}>
                      TIGGO 8 PRO <br />
                      <p className='f-20' style={{ color: "black", fontFamily: "TacticSans" }}>FIRST IMPRESSIONS LAST</p>
                    </span>
                  </h4>{" "}
                  <div
                    style={{
                      textAlign: "center",

                    }}
                  >
                    <div className='f-16 m-t-lg-5  mn-cl' style={{ color: "black" }}>
                      FROM
                    </div>
                    <div className='f-40 m-t-lg-0 mn-cl' style={{ color: "black" }}>
                      <CurrencyFormat
                        value="504 900"
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"R"}
                      />
                    </div></div>
                </Card>


              </Col>

            </Row>{" "}

          </div> */}
          <div
            className="row m-t-lg-50"
            style={{ marginBottom: window.isPhone ? "0em" : "50px" }}
          >
            <div className="col-md-12">
              <Tabs
                defaultActiveKey="1"
                onChange={callback}
                tabPosition="top"
                style={{ color: "black" }}
              >
                <TabPane tab="SHOWROOM" key="1">
                  <div className="row m-t-lg-20 m-b-lg-10 desktop-view">
                    <div className="product_detail no-bg p-lg-0">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="product-img-lg">
                            <Slider {...settings}>
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-1.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-2.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-3.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-4.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-5.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-6.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-7.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-8.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-9.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-10.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-11.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-12.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-13.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-14.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-15.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-16.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-17.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-18.jpg`}
                                alt=""
                              />
                              <Image
                                className="product-details-img"
                                src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-19.jpg`}
                                alt=""
                              />
                            </Slider>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="row  mobile-view">
                    <div
                      className="row"
                      style={{ marginBottom: "2em", textAlign: "center" }}
                    >
                      <Slider {...settings}>
                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-1.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-2.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-3.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-4.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-5.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-6.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-7.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-8.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-9.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-10.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-11.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-12.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-13.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-14.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-15.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-16.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-17.jpg`}
                          alt=""
                        />

                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-18.jpg`}
                          alt=""
                        />
                        <Image
                          className="product-details-img"
                          src={`${process.env.PUBLIC_URL}/images/tigg-8-pro-gal-19.jpg`}
                          alt=""
                        />
                      </Slider>
                    </div>
                  </div>{" "}
                </TabPane>
                <TabPane tab="TIGGO 8 PRO" key="2">
                  <div className="m-b-lg-30">
                    <div
                      className="heading-1 "
                      style={{ background: "rgb(197, 13, 39)" }}
                    >
                      <h3
                        className="f-18"
                        style={{ color: "white", paddingLeft: "1em" }}
                      >
                        TIGGO 8 PRO – ENJOY YOUR FIRST CLASS
                      </h3>
                    </div>

                    <div
                      className="m-b-lg-30 bg-gray-fa bg1-gray-2 p-lg-30 p-xs-15"
                      style={{ backgroundColor: "white", color: "black" }}
                    >
                      <p style={{ color: "black" }}>
                        Step in and step up your drive with the all new Tiggo 8
                        Pro. Luxuriously detailed and designed, packed with the
                        latest comfort, safety, convenience features, the Tiggo
                        8 Pro is a 7-seater, turbocharged, premium SUV ideal for
                        the businessperson on the rise, and their whole family.
                        Get ready for prime position and enjoy your first class!
                      </p>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="EXTERIOR" key="3">
                  <div
                    className="heading-1 "
                    style={{ background: "rgb(197, 13, 39)" }}
                  >
                    <h3
                      className="f-18"
                      style={{ color: "white", paddingLeft: "1em" }}
                    >
                      Exterior
                    </h3>
                  </div>
                  <ul className="product_para-1">
                    <li>
                      <strong>Dynamic Design</strong>
                      <br />
                      <br />
                      Designed by some of the world’s leading car stylists, the
                      Tiggo 8 Pro is a vivid expression of grace and power right
                      the way from the tiger-like front face and starry-night
                      grille to the penetrating beltline that runs all the way
                      to the back of the car.
                    </li>
                    <li>
                      <strong>Panoramic Sunroof</strong>
                      <br />
                      <br />
                      With a 1.96m2 skylight (both panels), the Tiggo 8 Pro
                      gives passengers a truly panoramic view of the sky,
                      whether they’re watching clouds, stars or skyscrapers.
                      With one-touch ON/OFF/Warped functionality, the glass
                      anti-pinch design protects occupants from accidental
                      injury, while the sunshade provides a 100% shading effect,
                      keeping any unwanted heat from the sun outside the cabin.
                    </li>
                    <li>
                      <strong>Sleek and Strong Roof Rack</strong>
                      <br />
                      <br />
                      Unobtrusive yet still super-functional, the Tiggo 8 Pro’s
                      aluminium alloy one-piece load-bearing roof rack supports
                      a load of up to 80kg, just in case the spacious interior
                      storage isn’t enough. In the Tiggo 8 Pro, you’ll never
                      have to go without.
                    </li>
                    <li>
                      <strong>Top-End 18-inch Alloy Rims</strong>
                      <br />
                      <br />
                      Made from super quality aluminium, the Tiggo 8 Pro’s alloy
                      rims are comparable to those found on a sports car and add
                      an athletic image to the exterior design.
                    </li>
                    <li>
                      <strong>
                        LED Headlamps with integrated Dynamic Turning Signal
                      </strong>
                      <br />
                      <br />
                      With a lighting efficiency of around 2.7 times that of a
                      traditional halogen lamp and integrated turning signal
                      lights with a dynamic flow direction for more visible
                      indication, the Tiggo 8 Pro’s lighting system radically
                      improves road illumination and driving safety.
                    </li>
                    <li>
                      <strong>Memory-Enabled Mirrors *</strong>
                      <br />
                      <br />
                      With automatic flat-folding and recovery memory of their
                      pre-set angle, the Tiggo 8 Pro’s exterior mirrors are just
                      another little extra that make driving the Tiggo 8 Pro a
                      first-class experience.
                      <br />
                      <br />
                      *Flagship models only
                    </li>
                  </ul>
                </TabPane>
                <TabPane tab="POWER" key="4">
                  <div
                    className="heading-1 "
                    style={{ background: "rgb(197, 13, 39)" }}
                  >
                    <h3
                      className="f-18"
                      style={{ color: "white", paddingLeft: "1em" }}
                    >
                      Power
                    </h3>
                  </div>
                  <ul className="product_para-1">
                    <li>
                      <strong>High Performance Engine</strong>
                      <br />
                      <br />
                      Both Tiggo 8 Pro models are equipped with a 1.6T GDI
                      Engine + 7DCT transmission with a maximum power of 145kW
                      and 290Nm of torque.
                    </li>
                    <li>
                      <strong>T1X Comfort Chassis</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro is built on the T1X platform. This chassis
                      has been deliberately crafted to enable better headroom
                      for front and rear seats, as well as higher ground
                      clearance. The high approach and departure angles also
                      ensure better off-road performance if and when required.
                    </li>
                    <li>
                      <strong>Dual Mode EPS</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro’s EPS (Electronic Power Steering) enables
                      light steering at a low speed and stable steering at a
                      high speed to deliver increased fuel efficiency compared
                      with hydraulic power systems.
                    </li>
                    <li>
                      <strong>Automatic Start/Stop</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro’s engine starts and stops automatically to
                      reduce emissions and reduce fuel consumption.
                    </li>
                    <li>
                      <strong>Elite NVH Levels</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro has an interior idling noise level of just
                      35dB. That’s as quiet as a library. Noise-reducing
                      material is used throughout the interior, exterior, body,
                      and chassis. An externally controlled variable
                      displacement compressor dampens noise from the front
                      compressor, cooling fan and rear fuel pump, while a
                      stepless fan effectively reduces noise in high-speed
                      conditions.
                    </li>
                    <li>
                      <strong>Towing Capacity</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro has a towing capacity of 750kg unbraked,
                      and 1 500kg braked.
                    </li>
                  </ul>
                </TabPane>

                <TabPane tab="TECHNOLOGY" key="7">
                  <div
                    className="heading-1 "
                    style={{ background: "rgb(197, 13, 39)" }}
                  >
                    <h3
                      className="f-18"
                      style={{ color: "white", paddingLeft: "1em" }}
                    >
                      Technology
                    </h3>
                  </div>
                  <ul className="product_para-1">
                    <li>
                      <strong>Intelligent Technology</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro is packed full of the latest technology,
                      multiple high-end screens, SONY sound system, wireless
                      charging for your devices, automatic window washer,
                      refrigerated armrest storage for a cold drink on a hot
                      day, and whole lot more.
                    </li>
                    <li>
                      <strong>Intelligent Driving Modes</strong>
                      <br />
                      <br />
                      The TIGGO 8 PRO features three high-end screens: a 7-inch
                      digital instrument cluster, an 8-inch touchscreen climate
                      control interface and 10.25-inch touchscreen infotainment
                      system with Apple CarPlay, Android Auto and voice
                      activation. It links to both your phone and the instrument
                      panel, seamless displaying multimedia, communication,
                      early warning, driving information, and other functions.
                      This tri-screen linkage of mobile phone, LCD instrument
                      panel and HD touch screen makes for a smoother, more
                      user-friendly, and safer driving experience.
                    </li>
                    <li>
                      <strong>ACC Adaptive Cruise Control</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro’s adaptive cruise control monitors road
                      conditions, vehicle spacing, automatic speed, regulation,
                      and start/stop.
                    </li>
                    <li>
                      <strong>BSD Blind Spot Detection</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro uses BSD (Blind Spot Monitoring) and LCA
                      (Lane Change Assist) with microwave radar to detect
                      passing cars and prevent accidents due to overtaking cars
                      moving through any potential blind spots in the rear-view
                      mirror.
                    </li>
                    <li>
                      <strong>Automatic Sensing Tail Door</strong>
                      <br />
                      <br />
                      The Tiggo 9 Pro’s electric tailgate opens automatically
                      when the remote control is brought within 1 metre of the
                      tailgate, sparing you the inconvenience of having to
                      search for your key when you want to load up the boot.
                    </li>
                    <li>
                      <strong>HD Display Screen & LCD Instrument Panel</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro’s HD floating centre LCD screen has a
                      resolution of up to 1920x720. It links to both your phone
                      and the instrument panel, seamless displaying multimedia,
                      communication, early warning, driving information, warning
                      message, setting and other functions. This tri-screen
                      linkage of mobile phone, LCD instrument panel, and HD
                      touch screen makes for a smoother, more user-friendly, and
                      safer driving experience. With its aesthetic and
                      user-friendly interface, the Tiggo 8 Pro’s screens come
                      loaded with three themes, freely switchable in the cabin
                      system, and customizable with wallpapers.
                      <br />
                      <br />
                      - Distinction models: 10.25” central floating display and
                      12.3” digital instruments display
                      <br />- Flagship executive models: 10.25” central floating
                      display, 12.3” digital instruments display plus centre
                      console mounted 8” ventilation control display
                    </li>
                    <li>
                      <strong>Keyless Entry & One Button Start</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro’s key comes with a PEPS (Passive Entry
                      Passive Start) and engine start/stop system, providing the
                      latest technological convenience. It allows automatic
                      unlocking at 1.2 meters from the vehicle, auto-lock up at
                      1.5 meters and with a simple long press of the lock key,
                      you can close all the windows simultaneously.
                    </li>
                    <li>
                      <strong>Electronic Gearshift</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro’s high-tech electronic gearshift is
                      unaffected by terrain when parking, making parking precise
                      and effortless.
                    </li>
                    <li>
                      <strong>Mirror Link</strong>
                      <br />
                      <br />
                      Tiggo 8 Pro is IOS and Android enabled, allowing for
                      voice-activated phone calls, voice-activated music, and
                      voice activated navigation commands.
                    </li>
                  </ul>
                </TabPane>
                <TabPane tab="SAFETY" key="8">
                  <div
                    className="heading-1 "
                    style={{ background: "rgb(197, 13, 39)" }}
                  >
                    <h3
                      className="f-18"
                      style={{ color: "white", paddingLeft: "1em" }}
                    >
                      Safety
                    </h3>
                  </div>
                  <ul className="product_para-1">
                    <li>
                      <strong>High-Strength Body</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro is constructed with multiple blocks of
                      Benteler AG ultra-high strength thermo-form steel with a
                      yield strength ≥1500Mpa per square inch!
                    </li>
                    <li>
                      <strong>6 Guard-Ring Airbags</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro is equipped with the leading guard-ring
                      type airbags, providing comprehensive protection in the
                      event of a collision. These include front driver and
                      passenger airbags, front side airbags and overlong side
                      curtain airbags that shield the interior of the cabin.
                    </li>
                    <li>
                      <strong>HD Reversing Camera</strong>
                      <br />
                      <br />
                      With HD quality, wide-angle images, the Tiggo 8 Pro’s
                      reverse image system displays dynamic auxiliary lines,
                      red, yellow, and green alerts. Coupled with ultrasonic
                      radar detector, it can accurately gauge distance, making
                      parking easy and stress free.
                    </li>
                    <li>
                      <strong>360-degree AVM (Around View Monitor)</strong>
                      <br />
                      <br />
                      With its HD quality, wide-angle image, AVM (Around View
                      Monitor) makes it easier to observe the obstacles around
                      the vehicle, making it simple for even novice drivers to
                      park, and reducing accidental bumps during parking or
                      low-speed driving. The panoramic view is automatically
                      activated when the reverse position is engaged and at a
                      speed of less than 20 km/h when steering. The setting
                      interface of the central control screen can be switched on
                      and off, and goes off automatically when the vehicle’s
                      speed is greater than 20 km/h.
                    </li>
                    <li>
                      <strong>EPB + Autohold</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro’s parking brake is activated by an
                      electronic lever tab to make sure that the braking force
                      can be applied to each wheel without differentiation.
                      Autohold maintains braking after the brake pedal is
                      depressed. This is deactivated when the accelerator is
                      tapped.
                    </li>
                    <li>
                      <strong>TPMS (Tire Pressure Monitoring System)</strong>
                      <br />
                      <br />
                      Each tyre on the Tiggo 8 Pro carries a pressure sensor
                      which communicates tyre pressure and temperature via
                      wireless radio frequency signals. When the tyre pressure
                      is less than 1.8bar and the vehicle is driving at a speed
                      over 30km/h, the system issues a low-pressure alarm after
                      the vehicle has driven for some time. When the tyre
                      temperature is greater than 85 C and the vehicle is
                      driving at speeds over 30km/h, the system issues a high
                      temperature alarm.
                    </li>
                  </ul>
                </TabPane>
                <TabPane tab="ADAS" key="6">
                  <div
                    className="heading-1 "
                    style={{ background: "rgb(197, 13, 39)" }}
                  >
                    <h3
                      className="f-18"
                      style={{ color: "white", paddingLeft: "1em" }}
                    >
                      ADAS
                    </h3>
                  </div>
                  <ul style={{ paddingLeft: "1em" }}>
                    <li>
                      <strong>ADAS (Advanced Driving Assistance System)</strong>
                      <br />
                      <br />
                      The Tiggo 8 Pro is equipped with ADAS (Advanced Driving
                      Assistance System) – a cluster of intelligent safety
                      functions to ensure your driving safety and the safety of
                      your passengers.
                      <br />
                      <br />
                    </li>
                    <strong>EBD</strong>(Electronic Brake Force Distribution)
                    <br />
                    <strong>ESP</strong>(ABS + BAS)
                    <br />
                    <strong>HAC</strong>(Hill-Start Assist Control)
                    <br />
                    <strong>HDC</strong>(Hill Decent Control)
                    <br />
                    <strong>TPMS</strong>(Tire Pressure Monitoring System)
                    <br />
                    <strong>BOS</strong>(Brake Override System)
                    <br />
                    <strong>BSD</strong>(Blind Spot Detection) *<br />
                    <strong>LDW</strong>(Lane Departure Warning) *<br />
                    <strong>ACC</strong>(Adaptive Cruise Control) *<br />
                    <strong>FCW</strong>(Front Collision Warning) *<br />
                    <strong>AEB</strong>(Automatic Brake System) *<br />
                    <strong>RCTA</strong>(Rear Cross Traffic Alert) *<br />
                    <strong>DOW </strong>(Door Opening Warning) *<br />
                    <strong>IHC</strong>(Intelligent High Beam Control) *<br />
                  </ul>
                </TabPane>
              </Tabs>
            </div>
          </div>
          {/* <Row
            style={{ maxWidth: "90em", margin: "auto" }}
            className="desktop-view"
          >
            <Col
              span={24}
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "30px",
                  textTransform: "none",
                  fontFamily: "TacticSansBd",
                  display: "block",
                  color: "grey",
                }}
              >
                EXPLORE IN 360°
              </span>
              <iframe
                src="https://chery.co.za/wp-content/uploads/3d_apps/tiggo8pro/index.html"
                frameborder="0"
                scrolling="no"
                style={{
                  touchActions: "none",
                  height: "30vw",
                  width: "80%",
                  borderRadius: "1em",
                  marginTop: "2em",
                }}
              ></iframe>
            </Col>
          </Row> */}
          {/* <Row
            style={{ maxWidth: "90em", margin: "auto", marginTop: "2em" }}
            className="mobile-view"
          >
            <Col
              span={24}
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontSize: "22px",
                  textTransform: "none",
                  fontFamily: "TacticSansBd",
                  display: "block",
                  color: "grey",
                }}
              >
                EXPLORE IN 360°
              </span>
              <iframe
                src="https://chery.co.za/wp-content/uploads/3d_apps/tiggo8pro/index.html"
                frameborder="0"
                scrolling="no"
                style={{
                  touchActions: "none",
                  height: "25em",
                  width: "95%",
                  borderRadius: "1em",
                  marginTop: "2em",
                }}
              ></iframe>
            </Col>
          </Row> */}
          <Row>
            <Col
              span={24}
              style={{
                textAlign: "center",
                marginTop: "3em",
              }}
            >
              <a
                href={"/brochures/Tiggo-8-Pro-Brochure.pdf"}
                target="_blank"
                className="ht-btn ht-btn-default m-t-lg-0 m-b-lg-30 p-l-lg-30 p-r-lg-30"
                style={{ textTransform: "none", borderRadius: "0.4em" }}
              >
                TIGGO 8 PRO SPECIFICATIONS
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{ marginLeft: "1em" }}
                ></i>
              </a>
            </Col>
          </Row>
          <Row style={{ maxWidth: "100vw", justifyContent: "center" }}>
            <Col
              span={24}
              style={{
                textAlign: "center",
                marginTop: "3em",
              }}
            >
              <p
                style={{
                  margin: "auto",
                  textTransform: "none",
                  fontSize: "20px",
                  color: "rgb(197, 13, 39)",
                  fontWeight: 600,
                }}
              >
                Pricing
              </p>
              <h3 className="f-weight-300" style={{ paddingTop: 0 }}>
                <strong
                  style={{
                    fontSize: "30px",
                    fontFamily: "TacticSans",
                    textTransform: "none",
                  }}
                >
                  Tiggo 8 Pro Trims
                </strong>
              </h3>
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                padding: "1em",
                border: "10px solid white",
                borderRadius: "2em",
                background: "linear-gradient(90deg, #0F0F0F 0%, #3F3F3F 100%)",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8p.png`}
                  alt=""
                  style={{
                    width: "20em",
                    filter: "invert(1)",
                    marginTop: "1em",
                  }}
                />
                <h4 className="product-name" style={{ marginBottom: "0em" }}>
                  <span
                    style={{
                      fontSize: "18px",
                      textTransform: "none",
                      fontFamily: "TacticSans",
                      display: "block",
                      color: "white",
                    }}
                  >
                    DISTINCTION 1.6
                    <br />
                  </span>
                </h4>
              </Col>
              <Col span={24}>
                <div
                  className="hover-img "
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Link
                    className="product-img"
                    // onClick={() => this.props.fetchSingleStock(stock)}
                    to={`/tiggo-8-pro`}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-8-p.webp`}
                      alt=""
                      style={{
                        width: "100%",
                        margin: "auto",
                      }}
                    />
                  </Link>
                </div>
              </Col>
              <Row>
                <Col span={24}>
                  <div
                    className=" m-t-lg-0 mn-cl"
                    style={{
                      fontSize: "30px",
                      color: "black",
                      textAlign: "center",
                      color: "#c50d27",
                      fontWeight: 700,
                    }}
                  >
                    <CurrencyFormat
                      value="529 900"
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"R"}
                    />
                  </div>
                </Col>
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                    marginBottom: "1em",
                  }}
                >
                  <Link
                    to={`#book-service`}
                    className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                    style={{
                      textTransform: "none",
                      borderRadius: "0.4em",
                    }}
                  >
                    TEST DRIVE
                    <i
                      class="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ marginLeft: "1em" }}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              md={8}
              style={{
                padding: "1em",
                border: "10px solid white",
                borderRadius: "2em",

                background: "linear-gradient(90deg, #0F0F0F 0%, #3F3F3F 100%)",
              }}
            >
              <Col span={24} style={{ textAlign: "center" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/chery-new/logo-8p.png`}
                  alt=""
                  style={{
                    width: "20em",
                    filter: "invert(1)",
                    marginTop: "1em",
                  }}
                />
                <h4 className="product-name" style={{ marginBottom: "0em" }}>
                  <span
                    style={{
                      fontSize: "18px",
                      textTransform: "none",
                      fontFamily: "TacticSans",
                      display: "block",
                      color: "white",
                    }}
                  >
                    EXECUTIVE 1.6
                    <br />
                  </span>
                </h4>
              </Col>
              <Col span={24}>
                <div
                  className="hover-img "
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Link
                    className="product-img"
                    // onClick={() => this.props.fetchSingleStock(stock)}
                    to={`/tiggo-8-pro`}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/chery-new/tiggo-8-p.webp`}
                      alt=""
                      style={{
                        width: "100%",
                        margin: "auto",
                      }}
                    />
                  </Link>
                </div>
              </Col>
              <Row>
                <Col span={24}>
                  <div
                    className=" m-t-lg-0 mn-cl"
                    style={{
                      fontSize: "30px",
                      color: "black",
                      textAlign: "center",
                      color: "#c50d27",
                      fontWeight: 700,
                    }}
                  >
                    <CurrencyFormat
                      value="582 900"
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"R"}
                    />
                  </div>
                </Col>
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                    marginBottom: "1em",
                  }}
                >
                  <Link
                    to={`#book-service`}
                    className="ht-btn ht-btn-default m-t-lg-10 p-l-lg-30 p-r-lg-30"
                    style={{
                      textTransform: "none",
                      borderRadius: "0.4em",
                    }}
                  >
                    TEST DRIVE
                    <i
                      class="fa fa-angle-right"
                      aria-hidden="true"
                      style={{ marginLeft: "1em" }}
                    ></i>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="m-t-lg-30">
              <div
                className="banner-item banner-2x banner-bg-10 color-inher p-lg-30"
                style={{ backgroundColor: "white" }}
              >
                <h3 className="f-weight-300">
                  <strong
                    style={{
                      lineHeight: "1em",
                      fontFamily: "TacticSans",
                      textTransform: "none",
                    }}
                  >
                    Make Vehicle Enquiry
                  </strong>
                </h3>
                <p
                  style={{
                    margin: "auto",
                    fontFamily: "TacticSans",
                    textTransform: "none",
                    fontSize: "15px",
                  }}
                >
                  Book your test drive or viewing today.
                </p>
              </div>
              <div className="bg1-gray-2">
                <Form
                  style={{ maxWidth: "80vw", margin: "auto" }}
                  id="book-service"
                  onFinish={this.handleSubmit}
                  initialValues={{
                    remember: true,
                  }}
                  name="wrap"
                  labelCol={{ flex: "110px" }}
                  labelAlign="left"
                  labelWrap
                  wrapperCol={{ flex: 1 }}
                  colon={false}
                >
                  <div
                    className="m-b-lg-20"
                    style={{ maxWidth: "40em", margin: "auto" }}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="name"
                          rules={[{ required: true, message: false }]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Name" />
                        </Form.Item>
                      </div>

                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="number"
                          rules={[
                            {
                              //max:10,
                              //min:10,
                              required: true,
                              message: false,
                              //type: "number",
                            },
                          ]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Number" />
                        </Form.Item>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="email"
                          rules={[
                            { required: true, message: false, type: "email" },
                          ]}
                          hasFeedback
                        >
                          <StyledInput placeholder="Email" />
                        </Form.Item>
                      </div>

                      <div className="text-center col-sm-12 col-md-12 col-lg-12">
                        <Form.Item
                          name="book"
                          rules={[{ required: false, message: false }]}
                        >
                          <Radio.Group
                            onChange={this.onChangeRadio}
                            value={this.state.valueRadio}
                          >
                            <Space
                              direction="horizontal"
                              style={{
                                display: window.isPhone ? "grid" : "",
                              }}
                            >
                              <Radio
                                style={{ color: "black" }}
                                value="Speak to a Dealer"
                              >
                                Speak to a Dealer
                              </Radio>
                              <Radio
                                style={{ color: "black" }}
                                value="Book a Test Drive"
                              >
                                Book a Test Drive
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="col-lg-12 m-t-lg-12 text-center">
                        <Form.Item>
                          <Button
                            style={{
                              fontFamily: "Arial",
                              border: "1px solid #CD2934",
                              fontSize: "15px",
                              fontWeight: 600,
                              borderRadius: "0em",
                              backgroundColor: "#CD2934",
                              color: "white",
                              height: "55px",
                              width: "100%",
                              paddingLeft: "3em",
                              paddingRight: "3em",
                              textShadow: "none",
                              margin: "auto",
                              marginTop: "2em",
                            }}
                            type="primary"
                            htmlType="submit"
                          >
                            Send Message
                          </Button>{" "}
                        </Form.Item>{" "}
                        <p
                          style={{
                            color: "black",
                            //marginBottom: "2.5em",
                            marginTop: "0em",
                            fontSize: "13px",
                            lineHeight: "1.5em",
                          }}
                        >
                          By submitting this form, you agree to our{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link style={{ color: "black" }} to="/terms">
                              Terms of Use
                            </Link>
                          </span>{" "}
                          and{" "}
                          <span
                            style={{
                              fontSize: "13px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link
                              style={{ color: "black" }}
                              to="/privacy-policy"
                            >
                              Privacy Statement
                            </Link>
                          </span>
                          .
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>{" "}
          </div>
        </section>{" "}
        <StyledModalTestDrive
          visible={this.state.visible}
          style={{ textAlign: "center" }}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/chery-white-header.png`}
            alt="Logo"
            style={{ width: "20em", margin: "auto", marginTop: "3em" }}
          />
          <h3
            style={{
              marginTop: "1em",
              color: "black",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Thank you for contacting
            <br />
            <span style={{ color: "rgb(214, 54, 41)" }}>
              CHERY KLERKSDORP.
            </span>{" "}
            <br />
            <br />
            <span style={{ color: "black ", fontSize: "17px" }}>
              Our vehicle specialist will be in touch shortly.
            </span>{" "}
            <br />
          </h3>{" "}
          <br />
        </StyledModalTestDrive>
      </div>
    );
  }
}
function mapStateToProps({ selectedStock }) {
  return { selectedStock };
}

export default connect(mapStateToProps)(withRouter(StockDetails));
