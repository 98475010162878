import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Carousel, Row, Spin, Col } from "antd";
import { withRouter, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
//import { Link } from "react-router-dom";
import SearchBarHome from "./SearchBarHome";
import HeaderAlternative from "./HeaderAlternative";

class SharedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dotPosition: "bottom",
      pexUnits: props.pexUnits,
      pexUnits: [],
      AllPexUnit: [],
      loading: false,
    };
  }
  // componentDidMount() {
  //   this.setState({ loading: true });
  //   fetch(
  //     "https://www.vcsappcloud.com/mtgroup/api/pexunit/findallpexunitclient",
  //     {
  //       method: "GET",
  //       mode: "cors",
  //       headers: {
  //         "Content-Type": "application/json; charset=utf-8",
  //       },
  //     }
  //   ).then(async (res) => {
  //     const result = await res.json();
  //     this.setState({
  //       pexUnits: result.allPexUnit,
  //       loading: false
  //     });
  //   });
  // }
  getFilters(filters) {
    this.props.stockfilters(filters);
  }

  renderSearchBar() {
    const { dotPosition } = this.state;
    const { pexUnits } = this.props;
    return (
      <div>
        <section className="block-sl desktop-view" style={{}}>
          <div className="container-custom">
            <div className="row">
              <div>
                <Carousel
                  autoplay
                  dotPosition={dotPosition}
                  dots={true}
                  effect="fade"
                  style={{
                    // width: "100vw",

                    // position: "absolute",
                    left: 0,
                    objectFit: "cover",
                  }}
                >
                  <span>
                    <div className="overlay">
                      <video
                        style={{
                          width: "100%",
                          height: "100vh",
                          objectFit: "cover",
                          overflowClipBox: "content-box",
                          overflow: "clip",
                          objectPosition: "center",
                        }}
                        autoPlay
                        muted
                        loop
                      >
                        <source
                          src={`${process.env.PUBLIC_URL}/images/chery-new/Chery-8-Pro-Max-Normal.webm`}
                          type="video/mp4"
                        ></source>
                      </video>
                    </div>
                  </span>
                  {/* {" "}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage && pexUnits.pageName === "Chery Controller" ? (
                      <span>
                        <div className="overlay">

                          <img
                            className="image-zoom"
                            // className='banner-img-home'

                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage}`}
                            alt='logo'
                          />
                        </div>
                      </span>
                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage2 && pexUnits.pageName === "Chery Controller" ? (
                      <span>
                        <div className="overlay">

                          <img
                            className="image-zoom"

                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage2}`}
                            alt='logo'
                          /> </div>
                      </span>

                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage3 && pexUnits.pageName === "Chery Controller" ? (
                      <span>
                        <div className="overlay">

                          <img
                            className="image-zoom"

                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage3}`}
                            alt='logo'
                          /></div>
                      </span>
                    ) : null;
                  })} */}
                </Carousel>
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-12"
                style={{
                  zIndex: 10,
                  textAlign: "center",
                  width: "100vw",
                  position: "absolute",
                  left: 0,
                  objectFit: "contain",

                  top: "60vh",

                  left: 0,
                  right: 0,
                }}
              >
                <div className="banner-item-home banner-2x-home no-bg color-inher-home">
                  <Fade bottom delay={200} casacde>
                    <p
                      style={{
                        marginBottom: "-0.5em",
                        fontSize: "25px",
                        marginTop: "0.5em",
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        color: "white",
                      }}
                    >
                      WELCOME TO
                    </p>
                  </Fade>
                  <Fade delay={300} casacde>
                    <h2
                      className="f-weight-500"
                      style={{
                        fontFamily: "TacticSans",
                        fontSize: "40px",
                        textAlign: "left",
                        marginTop: "-1.3em",
                        whiteSpace: "nowrap",
                        color: "white",
                        marginBottom: "-0em",
                        textTransform: "none",
                      }}
                    >
                      <br />
                      Chery Klerksdorp
                    </h2>
                  </Fade>
                  {/* <div style={{ textAlign: "left", }}>
                    <Link
                      to={"/kia-range"}
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{ textTransform: "none" }}
                    >
                      EXPLORE VEHICLES
                    </Link>
                    <Link
                      to="/ContactUs"
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{
                        textTransform: "none",
                        marginLeft: "1em",
                      }}
                    >
                      GET IN TOUCH
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <Row
            style={{
              margin: "auto",
              marginTop: "3em",
              textAlign: "center",
              maxWidth: "100vw",
            }}
          >
            <Col md={24}>
              <img src="/images/chery-background-img.jpg" alt="Promo-Image" />
            </Col>
          </Row>
          <Row
            gutter={12}
            style={{
              margin: "auto",
              marginTop: "3em",
              textAlign: "center",
              maxWidth: "100vw",
            }}
          >
            <Col md={4}></Col>
            <Col md={6}>
              <img
                src="/images/chery-promo-img-14.jpg"
                style={{ width: "100%" }}
                alt="Promo-Image"
              />
            </Col>

            <Col md={6}>
              <img
                src="/images/chery-promo-img-15.jpg"
                style={{ width: "100%" }}
                alt="Promo-Image"
              />
            </Col>

            <Col md={6}>
              <img
                src="/images/chery-promo-img-16.jpg"
                style={{ width: "100%" }}
                alt="Promo-Image"
              />
            </Col>

            {/* <Col md={4}>
              <img
                src="/images/chery-promo-img-17.jpg"
                style={{ width: "100%" }}
                alt="Promo-Image"
              />
            </Col> */}
            <Col md={2}></Col>
          </Row>
        </section>{" "}
        <section className="block-sl mobile-view" style={{ width: "100vw" }}>
          <div className="container-custom">
            <div className="row">
              <div>
                <Carousel
                  autoplay
                  dotPosition={dotPosition}
                  dots={true}
                  effect="fade"
                  style={{
                    left: 0,
                    objectFit: "cover",
                  }}
                >
                  <span>
                    <div className="overlay">
                      <img
                        style={{
                          width: "100%",
                          height: "100vh",
                          objectFit: "cover",
                          overflowClipBox: "content-box",
                          overflow: "clip",
                          objectPosition: "center",
                        }}
                        src="/images/chery-new/Group-16-2.webp"
                        alt="logo"
                      />
                    </div>
                  </span>
                  {/* {" "}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage && pexUnits.pageName === "Chery Controller" ? (
                      <span>
                        <div className="overlay">

                          <img
                            className="image-zoom"
                            // className='banner-img-home'

                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage}`}
                            alt='logo'
                          />
                        </div>
                      </span>
                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage2 && pexUnits.pageName === "Chery Controller" ? (
                      <span>
                        <div className="overlay">

                          <img
                            className="image-zoom"

                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage2}`}
                            alt='logo'
                          /> </div>
                      </span>

                    ) : null;
                  })}
                  {this.state.pexUnits.map((pexUnits) => {
                    return pexUnits.homeImage3 && pexUnits.pageName === "Chery Controller" ? (
                      <span>
                        <div className="overlay">

                          <img
                            className="image-zoom"

                            src={`${process.env.REACT_APP_GLOBAL_SERVER}mtgroup/api/images/${pexUnits.homeImage3}`}
                            alt='logo'
                          /></div>
                      </span>
                    ) : null;
                  })} */}
                </Carousel>
              </div>
              <div
                className="col-sm-12 col-md-12 col-lg-12"
                style={{
                  zIndex: 10,
                  textAlign: "center",
                  width: "100vw",
                  position: "absolute",
                  left: 0,
                  objectFit: "contain",

                  top: "70vh",

                  left: 0,
                  right: 0,
                }}
              >
                <div className="banner-item-home banner-2x-home no-bg color-inher-home">
                  <Fade bottom delay={200} casacde>
                    <p
                      style={{
                        marginBottom: "-0.5em",
                        fontSize: "20px",
                        marginTop: "0em",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        color: "white",
                      }}
                    >
                      WELCOME TO
                    </p>
                  </Fade>
                  <Fade delay={300} casacde>
                    <h2
                      className="f-weight-500"
                      style={{
                        fontFamily: "TacticSans",
                        fontSize: "25px",
                        textAlign: "center",
                        marginTop: "-1.3em",
                        whiteSpace: "nowrap",
                        color: "white",
                        marginBottom: "-0em",
                        textTransform: "none",
                      }}
                    >
                      <br />
                      Chery Klerksdorp
                    </h2>
                  </Fade>
                  {/* <div style={{ textAlign: "left", }}>
                    <Link
                      to={"/kia-range"}
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{ textTransform: "none" }}
                    >
                      EXPLORE VEHICLES
                    </Link>
                    <Link
                      to="/ContactUs"
                      className="ht-btn ht-btn-default m-t-lg-30 p-l-lg-30 p-r-lg-30"
                      style={{
                        textTransform: "none",
                        marginLeft: "1em",
                      }}
                    >
                      GET IN TOUCH
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <Row
            style={{
              margin: "auto",
              marginTop: "2em",
              textAlign: "center",
            }}
          >
            <Col
              md={24}
              style={{
                margin: "auto",
              }}
            >
              <img
                src="/images/chery-background-img.jpg"
                style={{ width: "95vw" }}
                alt="Promo-Image"
              />
            </Col>
          </Row>
          <Row
            style={{
              margin: "auto",
              marginTop: "2.5em",
              textAlign: "center",
              maxWidth: "25em",
            }}
          >
            <Col xs={24}>
              <img
                src="/images/chery-promo-img-14.jpg"
                width={300}
                height={300}
                alt="Promo-Image"
              />
            </Col>
            <Col xs={24}>
              <img
                src="/images/chery-promo-img-15.jpg"
                width={300}
                height={300}
                alt="Promo-Image"
                style={{
                  marginTop: "1em",
                }}
              />
            </Col>
            <Col xs={24}>
              <img
                src="/images/chery-promo-img-16.jpg"
                width={300}
                height={300}
                alt="Promo-Image"
                style={{
                  marginTop: "1em",
                }}
              />
            </Col>
            {/* <Col xs={12}>
              <img
                src="/images/chery-promo-img-17.jpg"
                width={162}
                height={162}
                alt="Promo-Image"
                style={{
                  marginTop: "1em",
                }}
              />
            </Col> */}
          </Row>
        </section>
        {/* <SearchBarHome filters={this.getFilters.bind(this)} /> */}
      </div>
    );
  }
  static propTypes = {
    location: PropTypes.object.isRequired,
  };
  render() {
    const { location } = this.props;
    return (
      <Spin spinning={this.state.loading}>
        <div
          id="wrap"
        // className='color1-inher'
        >
          <Header />

          {location.pathname === "/home" || location.pathname === "/"
            ? this.renderSearchBar()
            : null}
          <div id="wrap-body" className="p-t-lg-45">
            <div className="container-custom">{this.props.children}</div>
          </div>
          <Footer />
        </div>
      </Spin>
    );
  }
}
export default withRouter(SharedLayout);
